//1. install Node.js
//2. Add package.json file
//3. run => npm install
//4. run => npm install parcel
//5. run => npm install @azure/storage-blob
//6. run => npm start
//Refer: https://davenewman.tech/blog/host-node-namecheap-cpanel/

import NoSleep from '../scripts/NoSleep.js';

const sasToken = "sp=racwdli&st=2022-10-09T14:00:00Z&se=2024-10-09T14:00:00Z&spr=https&sv=2021-06-08&sr=c&sig=Y8rRcEOlJLhJeRzJixUrPWnJlPKtx67ZdUHqB8t1mkk%3D";
const blobUri = "https://minutesservicesstorage.blob.core.windows.net";
const blobSasUri = "https://minutesservicesstorage.blob.core.windows.net?" + sasToken;
const blobContainer = "mediablobcontainer";

const { BlobServiceClient } = require("@azure/storage-blob");
const blobServiceClient = new BlobServiceClient(blobSasUri);
const containerClient = blobServiceClient.getContainerClient(blobContainer);

const noSleep = new NoSleep();

// set up basic variables for app

const record = document.querySelector('.record');
const stop = document.querySelector('.stop');
const soundClips = document.querySelector('.sound-clips');
const canvas = document.querySelector('.visualizer');
const mainSection = document.querySelector('.main-controls');
const username = document.querySelector('.username');
const progress = document.querySelector('.progress');

var clipName = 'Untitled Audio';

// disable stop button while not recording

stop.disabled = true;

// visualiser setup - create web audio api context and canvas

let audioCtx;
const canvasCtx = canvas.getContext("2d");

//main block for doing the audio recording

if (navigator.mediaDevices.getUserMedia) {
  console.log('getUserMedia supported.');

  const constraints = { audio: true };
  let chunks = [];

  let onSuccess = function(stream) {
    const mediaRecorder = new MediaRecorder(stream);

    visualize(stream);

    record.onclick = function() {
      const currDateTime = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
      clipName = 'Untitled Audio ' + currDateTime;

      clipName = prompt('Enter a name for your audio?', clipName);

      if(clipName === null) {
        clipName = 'Untitled Audio ' + currDateTime;
      }

      mediaRecorder.start(10000); //timeslice of 10 sec
      console.log(mediaRecorder.state);
      console.log("recorder started");
      progress.innerHTML = '<p class="messageNeutral">Recording started...</p>';
      record.style.background = "red";

      noSleep.enable();
      stop.disabled = false;
      record.disabled = true;
    }

    stop.onclick = function() {
      mediaRecorder.stop();
      console.log(mediaRecorder.state);
      console.log("recorder stopped");
      progress.innerHTML = '<p class="messageNeutral">Recording stopped.</p>';
      record.style.background = "";
      record.style.color = "";

      noSleep.disable();
      stop.disabled = true;
      record.disabled = false;
    }

    mediaRecorder.onstop = function(e) {
      console.log("data available after MediaRecorder.stop() called.");

      const clipContainer = document.createElement('article');
      const clipLabel = document.createElement('p');
      const audio = document.createElement('audio');
      const deleteButton = document.createElement('button');

      clipContainer.classList.add('clip');
      audio.setAttribute('controls', '');
      deleteButton.textContent = 'Delete';
      deleteButton.className = 'delete';
      
      clipLabel.textContent = clipName;

      clipContainer.appendChild(audio);
      clipContainer.appendChild(clipLabel);
      clipContainer.appendChild(deleteButton);
      soundClips.appendChild(clipContainer);

      audio.controls = true;
      const blob = new Blob(chunks, { 'type': 'audio/wav; codecs=opus' });
      chunks = [];
      const audioURL = window.URL.createObjectURL(blob);
      audio.src = audioURL;
      console.log("mediaRecorder stopped");

      //sendAudioBlob(blob);
      //sendAudioBlobToAzure(blob);     
      
      deleteButton.onclick = function(e) {
        e.target.closest(".clip").remove();
      }

      clipLabel.onclick = function() {
        const existingName = clipLabel.textContent;
        const newClipName = prompt('Enter a new name for your audio?');
        if(newClipName === null) {
          clipLabel.textContent = existingName;
        } else {
          clipLabel.textContent = newClipName;
        }
      }
    }

      mediaRecorder.ondataavailable = function (e) {
        console.log('data chunk is available: ' + e.data);
          chunks.push(e.data);

          let appendChunks = []; //chunks to append in blob
          appendChunks.push(e.data);

          const blob = new Blob(appendChunks, { 'type': 'audio/wav; codecs=opus' });
          sendAudioBlobToAzure(blob);
    }
  }

  const sendAudioBlobToAzure = async (blob) => {
    try {
        clipName = clipName.replace(/[^a-zA-Z0-9_:-\s]/g,'_');
        const file = new File([blob], clipName + '.wav', { 'type': 'audio/wav; codecs=opus' });

        console.log('Sending audio to server...');
        progress.innerHTML = '<p class="messageNeutral">Sending audio to server...</p>';
        //const t0 = performance.now();
        let filename =  username.innerText + '/' + file.name; 
        const promises = [];

        //const blockBlobClient = containerClient.getBlockBlobClient(filename);
        //promises.push(blockBlobClient.upload(file, file.size));

        const appendBlobClient = containerClient.getAppendBlobClient(filename);
        try{
          appendBlobClient.createIfNotExists();
        }
        catch (error) {
          //
        }
        promises.push(appendBlobClient.appendBlock(file, file.size));

        await Promise.all(promises);
        console.log("Upload completed.");
        progress.innerHTML = '<p class="messageSuccess">Audio sent at ' + new Date().toLocaleTimeString() + '.</p>';
        //const t1 = performance.now();
        //console.log((t1 - t0)/60, 'seconds to upload');
    }
    catch (error) {
      console.log(error.message);
      progress.innerHTML = '<p class="messageError">Audio upload failed.. Please save the audio on your computer.</p>';
    }
}

  let onError = function(err) {
      console.log('The following error occured: ' + err);
      progress.innerHTML = '<p class="messageError">An error occured: ' + err + '</p>';
  }

  navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);

} else {
    console.log('getUserMedia not supported on your browser!');
    progress.innerHTML = '<p class="messageError">The recorder is not supported on your browser.</p>';
}

const sendAudioBlob = blob => {  
    console.log('Sending audio to server');
    progress.innerHTML = '<p class="messageNeutral">Sending audio to server...</p>';
    fetch('https://minutesservices.com/save-blob', {
        method: "post",
        body: blob,
        keepalive: true
    })
    .then((response) => response.status)
    .then((result) => {
        console.log('Success:', result);
        progress.innerHTML = '<p class="messageSuccess">Audio sent to server successfully.</p>';
    })
    .catch((error) => {
        console.error('Error:', error);
        progress.innerHTML = '<p class="messageError">Audio upload failed.. Please save the audio on your computer.<p>';
    });
};

function visualize(stream) {
  if(!audioCtx) {
    audioCtx = new AudioContext();
  }

  const source = audioCtx.createMediaStreamSource(stream);

  const analyser = audioCtx.createAnalyser();
  analyser.fftSize = 2048;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  source.connect(analyser);
  //analyser.connect(audioCtx.destination);

  draw()

  function draw() {
    const WIDTH = canvas.width
    const HEIGHT = canvas.height;

    requestAnimationFrame(draw);

    analyser.getByteTimeDomainData(dataArray);

    canvasCtx.fillStyle = 'rgb(200, 200, 200)';
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

    canvasCtx.beginPath();

    let sliceWidth = WIDTH * 1.0 / bufferLength;
    let x = 0;


    for(let i = 0; i < bufferLength; i++) {

      let v = dataArray[i] / 128.0;
      let y = v * HEIGHT/2;

      if(i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvas.width, canvas.height/2);
    canvasCtx.stroke();

  }
}

window.onresize = function() {
  canvas.width = mainSection.offsetWidth;
}

window.onresize();

document.body.onkeyup = function (e) {
  if (e.altKey && e.keyCode == 75) {
     e.preventDefault();
     record.click();
  }
  else if (e.altKey && e.keyCode == 76) {
    e.preventDefault();
    stop.click();
 }
}